import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import { Fade } from '@mui/material';

const classificationColors =  {
    Adverb : '#45425A' ,
    Adjective: '#169873' ,
    Noun: '#D295BF' ,
    Verb: '#FEB95F'
}

function show_definition_on_newline(definition){
    var definition = definition
    var definitionList = definition.map((param) => {
                            return <Typography sx={{paddingBottom:'5px'}}>{param}</Typography>})
    return(
        <div> 
            {definitionList}
        </div>
    )

}
function show_classification_on_newline(classification){
    var classification = classification
    var classificationList = classification.map((param) => {

                            return <Chip label={param}></Chip>})
    return(
        <Stack direction="row" spacing={1} sx={{paddingBottom:'5px', paddingTop:'5px'}}>
            {classificationList}
        </Stack>
        
    )

}

function DefinitionCard(word, defs, classifications) {
    // console.log(classifications)
    return (
        <Card sx={{marginBottom:'20px', marginLeft:'10px', marginRight:'10px'}}>
            <Fade in={true}>
                <CardContent spacing={1}>
                    <Typography variant="h5" component="div" sx={{padding:'5px'}}>
                    {word}
                    </Typography>
                    
                    {show_classification_on_newline(classifications)}
                                
                    {show_definition_on_newline(defs)}

                </CardContent>
            </Fade>
        </Card>
  );
}

function DefinitionCardContainer(props){
    
    if (props.items.length === 0) {
        return(
            <Box sx={{marginTop: '100px'}}>
                <Typography variant='p' sx={{fontWeight: 'bold'}}>
                    You have not looked up any definitions yet!
                </Typography>
            </Box>
        )}
    else {

        return (
            <Box sx={{height: 'max-content'}}>

                {props.items.map((item) => {
                    // console.log(item)
                    return(
                        
                            DefinitionCard(
                                item.Word,
                                item.Definition,
                                item.Classifications
                            )
                        
                        )
                    })}
            </Box>
        )
    
    }}

export default DefinitionCardContainer;
function jsonify_cookie(cookie){
    
    return cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
      }, {})

}

export default jsonify_cookie
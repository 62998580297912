import { Box } from "@mui/system"
import { Typography } from "@mui/material";
import React from "react";
import {Link, Divider} from "@mui/material";
function Footer() {
    return (
        <Box sx={{height:'15vh', bgcolor:'#85FFBD',background: 'linear-gradient(97deg, #85FFBD 0%, #FFFB7D 100%)', display:'flex', justifyContent:'center'}}>
            <Typography sx={{marginTop: '25px'}}>
                Thanks for checking out my app! 
                <Divider sx={{margin:'10px'}}></Divider>
                Connect with me on <Link href=''> LinkedIn</Link> 
            </Typography>
        </Box>
    )
}

export default Footer;

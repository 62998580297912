import {Button, Box} from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios'
import jsonify_cookie from './helpers/jsonifyCookie';



function LoginButton(props){
    

    const fetchToken = (code) => {
        const res = axios.get(`https://wsmith.app/auth?code=${code}`,{withCredentials:true})
        return res
    }

    // When you open the app, this doesn't do anything, but after you sign into Notion, you'll be redirected back with a code at which point we call our backend.
    useEffect(() => {
        const params = new URL(window.document.location).searchParams;
        const code = params.get("code");
        console.log(code)
        if (!code) return;
        if (code){
        props.setLoggingIn(true)}
    
    }
      );

    useEffect(() => {
        const params = new URL(window.document.location).searchParams;
        const code = params.get("code");
        fetch(`https://wsmith.app/auth?code=${code}`, {credentials:'include'}).then(async (resp) => {
                await props.setLoggedIn(jsonify_cookie(document.cookie)['access_token'])
                props.setLoggingIn(false)
    })},[props.isLoggingin])
    
    var cookie = (document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
      }, {}))
    
    const oauth_client_id = "ded7f112-0a2c-4761-89e3-9bb5483b2ff0";
    

    
    // console.log('access_token: ' + access_token)
    return (
        <Box sx={{padding:'30px'}}>
            <Button
                disabled={false}
                size="large"
                variant="outlined"
                href={`https://api.notion.com/v1/oauth/authorize?client_id=ded7f112-0a2c-4761-89e3-9bb5483b2ff0&response_type=code&owner=user`}>
                Connect to Notion 
            </Button>
        </Box>
    )
}

export default LoginButton;
import React from "react";
import { Box } from "@mui/system";
import { Divider, Typography } from "@mui/material";
import { useState } from "react";
import LoginButton from "./loginButton";
import InputForm from "./create";
import Typewriter from 'typewriter-effect';
import {CircularProgress} from "@mui/material";
import jsonify_cookie from "./helpers/jsonifyCookie";

<Typewriter
  options={{
    autoStart: true,
    loop: true,
  }}
/>

function Intro () {
    
    var [loggedIn,setLoggedIn] = useState()
    var [islogginIn, setLoggingIn] = useState(false);
    var cookie = (document.cookie.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
      }, {}))
    // var loggedin = cookie['access_token'] ? true : false
    
    console.log(loggedIn)
    if (islogginIn) {
        return (
        <Box sx={{height:'100%',display:'flex', flexDirection:'column', justifyContent:'center',alignItems:"center"}}>
            <CircularProgress color="secondary" />
        </Box>
            
        )
    }
    if  (!loggedIn){
        return (
            <Box sx={{height:'100%',display:'flex', flexDirection:'column', justifyContent:'center',alignItems:"center"}}>
            <Typography variant="h3" align="center" sx={{marginBottom:'5px'}} id='typewriter'>
                <Typewriter
                    onInit={(typewriter) => {
                        typewriter.typeString('Welcome to WordSmith!')
                        .start();
                    }}
                    />
                <Typography variant="h5">
                    Use your own Notion database to improve your vocabulary
                </Typography>
                {/* <br></br> */}
            </Typography>
            <LoginButton setLoggingIn={setLoggingIn} setLoggedIn={setLoggedIn}></LoginButton>
        </Box>
        
        )} else if(loggedIn){
            const {access_token, database_id, user_name} = jsonify_cookie(document.cookie)
            localStorage.setItem('access_token', access_token)
            localStorage.setItem('database_id', database_id)
            localStorage.setItem('user_name', user_name)
            return (
                
            
            <InputForm></InputForm>

   
    )}
}

export default Intro
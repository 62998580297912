import { Alert, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';



function Notifications(props){
    var [fadeIn, setFadeIn] = useState(props.showNoti)
    
    var mapping =  {
        definition_not_found : {
            color : 'error',
            severity: 'error',
            notificationText : 'We couldnt find a definition for the word you selected. Check your spelling, or try a new one!'
        },
        login_error : {
            color : 'warning',
            severity : 'error',
            notificationText : 'An error occurred while we tried to log you in'
        } 
    }
    console.log(fadeIn)
    if (props.type === '') {
        return(
            <Box></Box>
        )
    } else {
        var severity = mapping[props.type]['severity']
        
        return(
            <Fade in={props.showNoti}>
                    <Alert 
                        severity={severity}
                        variant = 'outlined' 
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    props.setShowNoti(false)
                                }}
                                >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        >
                        {mapping[props.type].notificationText}
                    </Alert>
            </Fade>
        )
}
    }
export default Notifications
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from '@mui/material';

export default function HelpSection() {
  return (
    <div>
      <Typography sx={{marginBottom:'30px'}}variant='h3' align='center'>
        FAQs:
      </Typography>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>What do I need to use this app?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            You need a Notion account to use this app. Notion is a free application. It provides blank canvases called
            "pages" which are used to insert other components such as tables, text, images, tackerts, etc. 
            To get started, <Link href='notion.so'>create a Notion account</Link> And click on 'Connect to Notion' button.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Where are the definitions stored?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The definitions are stored within a a Notion "database" hosted in your account. 
            When you log in for the first time, use the template provided by us. You can clone this in your workspace and use it next time you log in if you need a different page to store your definitions.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>How does authentication work with this app?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The app uses OAuth 2.0 gain the necessary access to your pages. The app only sees the pages you grant access to.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>What information do you store?</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            We store no personal information. Every definition is stored in your personal Notion worskpace.
          </Typography>
        </AccordionDetails>
      </Accordion>

      
    </div>
  );
}
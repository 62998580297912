import { useEffect, useState } from "react";
import InputForm from "./pages/create";
import appBar from "./layout/appBar";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import {Button} from '@mui/material';
import LoginButton from './pages/loginButton'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Intro from "./pages/intro";
import Footer from "./pages/footer";
import HelpSection from "./pages/help";

function App() {

  return (
    <div>
        <AppBar position="static" sx={{bgcolor:'#85FFBD',background: 'linear-gradient(97deg, #85FFBD 0%, #FFFB7D 100%)'}}>
          <Toolbar variant="dense">
              <Typography variant="h6" color="#000000" component="div" sx={{fontWeight: 'bold'}}>
                WordSmith
              </Typography>
          </Toolbar>
        </AppBar>

        <Box sx={{height:'100vh',bgcolor:'#F0EFF4',background:'linear-gradient(180deg, #f0eff4 0%, #cfcfcf 99%)', overflowY:'scroll'}}>
          <Intro/>
        </Box>

        <Box sx={{height:'max-content', display:'block',bgcolor:'#F0EFF4', background:'linear-gradient(0deg, #f0eff4 0%, #cfcfcf 99%)' ,display:'flex', justifyContent:'center'}}>
          <Container sx={{ marginTop: '30px', marginBottom:'100px'}}>
            
            <HelpSection></HelpSection>

          </Container>

        </Box>
        <Footer></Footer>
    </div>
  );
}

export default App;

import React from 'react';
import { useState } from 'react';
import { useCallback } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import jsonify_cookie from './helpers/jsonifyCookie';
import DefinitionCardContainer from './DefinitionCard';
import axios from 'axios';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Alert } from '@mui/material';
import Notifications from '../layout/notifications'

function InputForm() {

    const [notificationType, setNotificationType] = useState('')
    var [showNoti, setShowNoti] = useState(false)
    var [items, addItem] =  useState([])

    // var testData = {Word:'hello',Classifications:['verb','nount'],Definition:['greeting']}

    var [enteredWord, setWord] = useState('')
    var [enteredBook, setBook] = useState('')
    var [enteredPage, setPage] = useState('')

    const handleWordChange = (event) => {
        console.log(enteredWord)
        setWord(event.target.value)
    }
    const handleBookChange = (event) => {
        setBook(event.target.value)
    }
    const handlePageChange = (event) => {
        setPage(event.target.value)
    }
    var returned = ''


    const sendRequest = (event) => {
        
        axios.post('https://wsmith.app/define', {
            access_token : localStorage.getItem('access_token'),
            database_id : localStorage.getItem('database_id'),
            word: enteredWord,
            book: enteredBook,
            page : enteredPage
          }).then((data) => {  
                if ('Alert' in data.data){
                    setNotificationType(data.data['Alert'])
                    setShowNoti(true)
                    
                } else {
                addItem([ data.data,...items])
                setNotificationType('')
            }
            })
          
          setWord('')
          setBook('')
          setPage('')
        // console.log(enteredBook,enteredWord,enteredPage)
    }

    const theme = createTheme({
    palette: {
        primary: {
        main: '#0971f1',
        darker: '#053e85',
        },
        button: {
            main: '#4C5760',
            contrastText: '#fff',
        },
        periwinkle: {
            main: '#CBC5EA',
            contrastText: '#fff',
        },
    },
    });

    
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{height:'60px', margin:'5px' ,marginBottom:'0px'}}>
                {
                    <Notifications type={notificationType} showNoti={showNoti} setShowNoti={setShowNoti}></Notifications>
                }
            </Box>
            <Box sx={{display:'flex', flexDirection:'column', alignItems:'center', justifyContent: 'center'}}>
                <Box sx={{marginLeft:'100px',marginRight:'100px', marginBottom:'10px', marginTop:'30px'}}>                    
                    <TextField size='small' sx={{margin:'10px', width:'180px'}}id="outlined-basic" value={enteredWord} onChange={handleWordChange} label="Word" variant="outlined" required/>
                    <TextField size='small' sx={{margin:'10px', width:'180px'}}id="outlined-basic" value={enteredBook} onChange={handleBookChange} label="Book/Text" variant="outlined" />
                    <TextField size='small' sx={{margin:'10px', width:'180px'}}id="outlined-basic" value={enteredPage} onChange={handlePageChange} label="Page" variant="outlined" />
                    <Button variant="outlined" color='button' sx={{margin:'10px',height:'40px', width:'180px'}} size='large' onClick={sendRequest}>add</Button>
                </Box>
                <DefinitionCardContainer items={items}></DefinitionCardContainer>
            </Box>
        </ThemeProvider>
        )
                        
    

}
export default InputForm;
